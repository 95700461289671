import ReactGA from 'react-ga';
// export const app = document.getElementById('app');
// export const BODY_ELEMENT = document.body;
var  WIN = { baz: "bar" };
function matchMedia(){

}
if (typeof window !== 'undefined'){
    WIN = window;
    
}


export const KNOCK_EVENTS = {
    'viewportchange': 'knock.viewportsizechange'
};

// export const IS_MOBILE_SCREEN = screen.width <= 767;

export const SUPPORTS_HOVER = !('ontouchstart' in WIN);


// const mql = matchMedia('(max-width: 767px)');
// export let IS_MOBILE_VIEWPORT = mql.matches;
// mql.addListener(e => {
//     IS_MOBILE_VIEWPORT = e.matches;

//     //trigger event for change
//     const event = new CustomEvent(KNOCK_EVENTS.viewportchange, {detail: {isMobile: IS_MOBILE_VIEWPORT} });
//     WIN.dispatchEvent(event);
// });




/**
 * Generates a url path to an asset in the theme directory
 * @param path
 * @returns {string}
 */
// export function asset_url(path){
//     return `/wp-content/themes/excel/${path}`
// }


/**
 * Math function to scale a number from one range to another range
 * @param {number} value
 * @param {number} low1
 * @param {number} high1
 * @param {number} low2
 * @param {number} high2
 * @returns {number}
 */
export function map_range(value, low1, high1, low2, high2) {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}


/**
 * @see map_range Uses map_range to scale a number from one range to 0 - 1
 * @param {number} value
 * @param {number} low
 * @param {number} high
 * @returns {number}
 */
export function normalize_range(value, low, high){
    return map_range(value, low, high, 0, 1);
}

/**
 * Clamps a number between two values
 * @param {number} value
 * @param {number} low
 * @param {number} high
 * @returns {number}
 */
export function clamp(value, low, high){
    if(value < low) value = low;
    if(value > high) value = high;
    return value;
}

// export function hexToRGB(hex){
//     // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
//     const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
//     hex = hex.replace(shorthandRegex, function(m, r, g, b) {
//         return r + r + g + g + b + b;
//     });

//     const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//     return result ? {
//         r: parseInt(result[1], 16),
//         g: parseInt(result[2], 16),
//         b: parseInt(result[3], 16)
//     } : null;
// }

// export function preventWindowAutoScrollOnRefresh(){

//     if ('scrollRestoration' in history) {
//       history.scrollRestoration = 'manual';
//     } else {
//         window.onunload = function(){ window.scrollTo(0,0); }
//     }

// }

export function customGAEvent(evtArgs){
    console.log('custom event triggering: ', evtArgs);
    ReactGA.event(evtArgs);
}
