import React from 'react';
import PropTypes from 'prop-types';
import VideoControls from "./VideoControls";
import classNames from 'classnames';
import bindAll from 'lodash-es/bindAll';
import {customGAEvent} from "../../Utilities";
import WithViewportSizeDetector from "../WithViewportSizeDetector";

class VideoElementComponent extends React.Component {

    static defaultProps = {
        playsInline: true,
        playOverlay: true,
        color: '#000000',
        autoPlay: false,
        loop: false,
        controls: true,
        url: '',
        title: '',
        poster: '',
        description: ''
    };

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();

        this.state = {
            playing: false,
            currentTime: 0,
            duration: 0,
            volume: this.props.muted ? 0 : .5
        };

        bindAll(this, ['onLoadedMetaData', 'defineFSMethods', 'onVideoPlay', 'onVideoPause',
            'onVideoTimeUpdate', 'onVideoEnded', 'onVolumeChange',
            'play', 'pause', 'seek', 'volume', 'fullScreen']);

        this.defineFSMethods();
    }

    playVideo(e){
        var charCode = e.charCode || e.keyCode;
        var currentPlayState = this.state.playing
        if(charCode == 13) {
            console.log('hi')
            if(this.state.playing == false){
                this.videoRef.current.play();
            }else{
                this.videoRef.current.pause();
            }
        }
    }

    defineFSMethods(){
        if (typeof document !== 'undefined'){
            const enterMethods = ['requestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullscreen', 'msRequestFullscreen'];
            const exitMethods = ['exitFullscreen', 'mozCancelFullScreen', 'webkitExitFullscreen', 'msExitFullscreen'];

            this.enterFSMethod = enterMethods.find(item => document.documentElement[item] !== undefined);
            this.exitFSMethod = exitMethods.find(item => document[item] !== undefined);
        }
    }

    render() {

        const {url, poster, loop, autoPlay, controls, color, muted, mobile, playOverlay, elementClass, vidElementClass, wrapperClass, ...rest} = this.props;
        const videoClassNames = classNames('video-container', wrapperClass);
        const videoElementWrapperClass = classNames('video-element-wrapper', elementClass);
        const videoElementClass = classNames('scalableImg video-element', vidElementClass);

        const description = (this.props.description) ? this.props.description : this.props.title;


        return (
            <div className={videoClassNames}>
                <div className={videoElementWrapperClass}>
                    {!this.state.playing &&
                        <div className="play-overlay-btn" onClick={this.play} tabIndex="0" onKeyPress={(e) => this.playVideo(e)}>
                            <svg width="154px" height="154px" viewBox="0 0 154 154" version="1.1">
                                <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="University---Desktop" transform="translate(-643.000000, -3469.000000)">
                                        <g id="Group-Copy-4" transform="translate(645.000000, 3471.000000)">
                                            <g id="Group-18">
                                                <circle id="Oval" stroke="#FFFFFF" stroke-width="3" cx="75" cy="75" r="75"></circle>
                                                <polygon id="Rectangle" fill="#FFFFFF" transform="translate(71.965805, 75.000000) rotate(-315.000000) translate(-71.965805, -75.000000) " points="57.9658053 69.1954583 85.9658053 61 77.7703471 89"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    }
                    {playOverlay && !this.state.playing &&
                    <div className={'video-play-overlay'} onClick={this.play} tabIndex="0"><span
                        className={'play-btn cta cta--pill'} >Play Video</span></div>}
                    <video title={this.props.title} onClick={e => this.state.playing ? this.pause() : this.play()}
                           className={videoElementClass} onPlay={this.onVideoPlay} onEnded={this.onVideoEnded}
                           onPause={this.onVideoPause} onTimeUpdate={this.onVideoTimeUpdate}
                           ref={this.videoRef} poster={poster} preload={'metadata'} playsInline={true} loop={loop}
                           autoPlay={autoPlay} onLoadedMetadata={this.onLoadedMetaData} onVolumeChange={this.onVolumeChange}
                           muted={autoPlay || muted} aria-label={description} controls={mobile && controls} {...rest}>
                        <source type={'video/mp4'} src={url}/>
                    </video>
                </div>

                {controls && !mobile && <VideoControls playing={this.state.playing} elapsed={this.state.currentTime}
                                            duration={this.state.duration} className={this.props.controlsClass}
                                            play={this.play} pause={this.pause} seek={this.seek}
                                            fullscreen={this.enterFSMethod ? this.fullScreen : false}
                                            volume={this.state.volume} volumeChange={this.volume}/>}
            </div>

        )
    }

    //// video methods////
    play() {
        this.videoRef.current.play();
    }

    pause() {
        if (this.props.controls) {
            this.videoRef.current.pause();
        } else {
            if (!this.props.autoPlay && !this.props.loop) this.videoRef.current.pause();
        }

    }

    seek(time) {
        this.videoRef.current.currentTime = Math.floor((time / 100) * this.videoRef.current.duration);
    }

    fullScreen() {
        this.videoRef.current[this.enterFSMethod]();
    }

    volume(vol){
        console.log(vol)
        this.videoRef.current.volume = vol;
    }

    ///video events////
    onLoadedMetaData() {
        this.setState(prevState => {
            return {...prevState, duration: this.videoRef.current.duration}
        });
    }

    onVideoPlay(e) {
        this.setState(prevState => {
            return {...prevState, playing: true};
        });
    }

    onVideoPause(e) {
        this.setState(prevState => {
            return {...prevState, playing: false};
        });
    }

    onVolumeChange(e){
        this.setState(prevState => {
            return {...prevState, volume: this.videoRef.current.volume}
        });
    }

    onVideoTimeUpdate(e) {
        this.setState(prevState => {
            if(this.props.gatimeevents){
                const perc = (this.videoRef.current.currentTime / this.videoRef.current.duration) * 100;
                customGAEvent({category: "Page Interaction", action: `Video Watched ${perc.toFixed(2)}%`, label: this.props.galabel})
            }
            return {...prevState, currentTime: this.videoRef.current.currentTime};
        });
    }

    onVideoEnded(e) {
        if (!this.props.loop) {
            this.seek(0);
            this.pause();
        }
    }
}



const VideoElement = WithViewportSizeDetector(VideoElementComponent);
VideoElement.propTypes = {
    url: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    color: PropTypes.string,
    elementClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    vidElementClass: PropTypes.string,
    playOverlay: PropTypes.bool,
    title: PropTypes.string,
    poster: PropTypes.string,
    muted: PropTypes.bool,
    description: PropTypes.string,
    gatimeevents: PropTypes.number,
    galabel: PropTypes.string
};
export default VideoElement;