import React from 'react';
import bindAll from "lodash-es/bindAll";
import {IS_MOBILE_VIEWPORT, KNOCK_EVENTS} from "../Utilities";

const WithViewportSizeDetector = WrappedComponent => {
    return class WithViewportSizeDetectorWrapper extends React.Component {
        constructor(props){
            super(props);
            this.state = { mobile: IS_MOBILE_VIEWPORT };
            bindAll(this, ['handleViewportChange']);
        }

        componentDidMount() {
            window.addEventListener(KNOCK_EVENTS.viewportchange, this.handleViewportChange);
        }

        componentWillUnmount() {
            window.removeEventListener(KNOCK_EVENTS.viewportchange, this.handleViewportChange);
        }

        handleViewportChange(e){
            const isMobile = e.detail.isMobile;
            this.setState(prevState => {
                return {
                    ...prevState,
                    mobile: isMobile
                }
            });
        }

        render(){
            return <WrappedComponent mobile={this.state.mobile} {...this.props}/>
        }
    }
};

export default WithViewportSizeDetector;
