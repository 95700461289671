import React from 'react';
import classNames from 'classnames';
import VolumeControl from "./VolumeControl";

const IntWithLength = (num, length, padString = '0') => {
    let ret = "" + num;
    while (ret.length < length) ret = `${padString}${ret}`;

    return ret;
};

const formatSeconds = seconds => {
    const leftSide = IntWithLength(Math.floor(seconds / 60), 2);
    const rightSide = IntWithLength(Math.floor(seconds % 60), 2);

    return `${leftSide}:${rightSide}`;
};


const VideoControls = props => {
    //function props
    const {play, pause, fullscreen, volumeChange} = props;

    //variable props
    const { playing, elapsed, duration, volume} = props;

    const controlsClass = classNames('video-controls', props.className);

    const pauseVidePPB = (evt) => { var charCode = evt.charCode || evt.keyCode; console.log(charCode); if(charCode == 13){ pause() }}

    const fullscreenKD  = (evt) => { var charCode = evt.charCode || evt.keyCode; console.log(charCode); if(charCode == 13){ fullscreen() }}

    return (
        <div className={controlsClass}>
            <div className={'play-state'} onClick={e => playing ? pause() : play()} tabIndex={playing ? "0" : ""} onKeyPress={e => pauseVidePPB(e)}>
                { playing && 
                    <div>
                        <svg width="21px" height="24px" viewBox="0 0 21 24" version="1.1">
                            <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="University---Desktop" transform="translate(-240.000000, -2845.000000)" fill="#0B1C3E">
                                    <g id="Group-11-Copy-2" transform="translate(240.000000, 2841.000000)">
                                        <g id="Group-13-Copy-4" transform="translate(0.000000, 4.000000)">
                                            <rect id="Rectangle" x="13" y="0" width="8" height="24"></rect>
                                            <rect id="Rectangle-Copy" x="0" y="0" width="8" height="24"></rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div> 
                }
                { !playing && 
                    <div className="visually-hidden">
                        <svg width="21px" height="24px" viewBox="0 0 21 24" version="1.1">
                            <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="University---Desktop" transform="translate(-240.000000, -2845.000000)" fill="#0B1C3E">
                                    <g id="Group-11-Copy-2" transform="translate(240.000000, 2841.000000)">
                                        <g id="Group-13-Copy-4" transform="translate(0.000000, 4.000000)">
                                            <rect id="Rectangle" x="13" y="0" width="8" height="24"></rect>
                                            <rect id="Rectangle-Copy" x="0" y="0" width="8" height="24"></rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div> 
                }
            </div>
            <div className={'video-times row between-xs'}>
                <span className={'time-elapsed time-text'}>{formatSeconds(elapsed)}</span>&nbsp;/&nbsp;
                <span className={'time-total time-text'}>{formatSeconds(duration)}</span>
            </div>
            <div className={'vid-time-track'}>
                <div className={'vid-track-elapsed'} style={{width: `${(elapsed / duration) * 100}%`}}>&nbsp;</div>
                
            </div>

            <div className={'right-controls'}>
                <VolumeControl volume={volume} change={volumeChange}/>
                {fullscreen &&
                <figure onClick={fullscreen} aria-label={'Full Screen'} className={'fullscreen-button'} tabIndex="0" onKeyPress={(e) => fullscreenKD(e)}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="University---Desktop" transform="translate(-1175.000000, -2846.000000)">
                                <g id="Group-11-Copy-2" transform="translate(240.000000, 2841.000000)">
                                    <g id="Group-12-Copy-4" transform="translate(935.000000, 5.000000)">
                                        <rect id="Rectangle" stroke="#0B1C3E" stroke-width="3" x="3" y="2" width="19" height="19"></rect>
                                        <rect id="Rectangle" fill="#FFFFFF" x="0" y="8" width="24" height="8"></rect>
                                        <polygon id="Rectangle" fill="#FFFFFF" transform="translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) " points="1 8 24 8 24 16 1 16"></polygon>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </figure>}
            </div>
        </div>
    )
};

export default VideoControls;