import React from 'react';
import bindAll from "lodash-es/bindAll";
import classNames from 'classnames';
import PropTypes from 'prop-types';

class VolumeControl extends React.Component {

    state = {
        showTrack: false,
        volume: this.props.volume
    };

    ref = React.createRef();

    constructor(props) {
        super(props);
        bindAll(this, ['handleVolumeClick', 'handleVolumeChange', 'toggleTrackVisibility', 'handleVolumeKeyPress']);
    }

    handleVolumeClick(evt) {
        evt.nativeEvent.stopPropagation();
        this.toggleTrackVisibility();

    }


    handleVolumeChange(evt){
        const val = parseFloat(evt.target.value);
        this.setState(prevState=>{
            this.props.change(val);
            return {...prevState, volume: val}
        });

    }

    handleVolumeChangeKeyPress(evt){
        var charCode = evt.charCode || evt.keyCode;
        const val = document.getElementById('volume-knob').value;
        console.log(val)
            // if(charCode == 39) {
            //   val + 0.05
            // }else{
            //   val - 0.05
            // }
    }

    handleVolumeKeyPress(evt){
        var charCode = evt.charCode || evt.keyCode;
        let elem = document.getElementById('volume-knob')
        let val = document.getElementById('volume-knob').value;

        if(charCode == 13) {
            evt.nativeEvent.stopPropagation();
            this.toggleTrackVisibility();
        }else if(charCode == 39) {
            console.log(val + .05)
            evt.nativeEvent.stopPropagation();
            elem.value = parseFloat(val) + .05
            console.log(elem.value)
            this.setState(prevState=>{
                this.props.change(elem.value);
                return {...prevState, volume: elem.value}
            });
        }else if(charCode == 37) {
            evt.nativeEvent.stopPropagation();
            elem.value = parseFloat(val) - .05
            this.setState(prevState=>{
                this.props.change(elem.value);
                return {...prevState, volume: elem.value}
            });
        }

    }

    toggleTrackVisibility(state){
        this.setState(prevState => {
            const visibility = state === undefined ? !prevState.showTrack : state;
            return {...prevState, showTrack: visibility}
        })
    }

    render() {
        const { volume, showTrack } = this.state;
        const trackClass = classNames('volume-range', {visible: showTrack});

        return (
            <figure aria-label={'Volume Control'} className={'volume-button'}>
                <input aria-label={'Volume Knob'} id="volume-knob" onBlur={e => { this.toggleTrackVisibility(false); }} onChange={this.handleVolumeChange}
                       value={volume} type={'range'} max={1} min={0} step={.05} className={trackClass} />

                <div className={'volume-icon-wrap'} onClick={this.handleVolumeClick} tabIndex="0" onKeyDown={this.handleVolumeKeyPress} onFocus={this.handleVolumeChangeKeyPress}>
                    {volume == 0 && 
                        <div className="volume-control mute">
                            <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1">
                                <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="University---Desktop" transform="translate(-1122.000000, -3839.000000)">
                                        <g id="Group-11" transform="translate(240.000000, 3840.000000)">
                                            <g id="Group-18" transform="translate(882.000000, 2.000000)">
                                                <g id="Group-7-Copy-4" transform="translate(0.000000, 2.000000)">
                                                    <path d="M4,15.0157783 L4,8.98422173 L15,-1.34143363e-10 L15,24 L4,15.0157783 Z" id="Combined-Shape-Copy-6" fill="#0B1C3E"></path>
                                                    <rect id="Rectangle-Copy-7" fill="#0B1C3E" x="0" y="6" width="9" height="11"></rect>
                                                    <path d="M17,2.95432095 C21.5920108,3.91773648 25,7.47527094 25,11.716547 C25,15.9578231 21.5920108,19.5153576 17,20.4787731 L17,8.39108567 L17,2.95432095 Z" id="Combined-Shape-Copy-7" stroke="#0B1C3E" stroke-width="3"></path>
                                                    <rect id="Rectangle-Copy-8" fill="#FFFFFF" x="15" y="0" width="4" height="24"></rect>
                                                </g>
                                                <path d="M3,0 L26.5,23.5" id="Line-11" stroke="#0B1C3E" stroke-width="3" stroke-linecap="square"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    }
                    {volume > 0 && 
                        <div className="volume-control scale" icon={'volume-icon'} loud={volume > .5}>
                            <svg width="27px" height="24px" viewBox="0 0 27 24" version="1.1">
                                <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="University---Desktop" transform="translate(-1122.000000, -2845.000000)">
                                        <g id="Group-11-Copy-2" transform="translate(240.000000, 2841.000000)">
                                            <g id="Group-7-Copy-4" transform="translate(882.000000, 4.000000)">
                                                <path d="M4,15.0157783 L4,8.98422173 L15,-1.34143363e-10 L15,24 L4,15.0157783 Z" id="Combined-Shape-Copy-6" fill="#0B1C3E"></path>
                                                <rect id="Rectangle-Copy-7" fill="#0B1C3E" x="0" y="6" width="9" height="11"></rect>
                                                <path d="M17,2.95432095 C21.5920108,3.91773648 25,7.47527094 25,11.716547 C25,15.9578231 21.5920108,19.5153576 17,20.4787731 L17,8.39108567 L17,2.95432095 Z" id="Combined-Shape-Copy-7" stroke="#0B1C3E" stroke-width="3"></path>
                                                <rect id="Rectangle-Copy-8" fill="#FFFFFF" x="15" y="0" width="4" height="24"></rect>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    }
                </div>
            </figure>
        )
    }

}

const customVolumeChecker = (isRequired) => {
    return (props, propName, componentName) => {
        const val = props[propName];
        if(isRequired && val === null){
            //required, throw error
            return new Error(`${propName} is required for ${componentName} component`);
        } else {
            if (isNaN(val) || val < 0 || val > 1) {
                return new Error(`${val} is an invalid Prop for ${componentName} component. Looking for a number between 0 and 1`);
            }
            return null;
        }
    }
};

const volumeChecker = customVolumeChecker(false);
volumeChecker.isRequired = customVolumeChecker(true);

VolumeControl.propTypes = {
    volume: volumeChecker.isRequired,
    change: PropTypes.func.isRequired
};

export default VolumeControl;